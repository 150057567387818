<template>
    <div>
         <con-tab @TwoClick="TwoClick"></con-tab>
       <!-- 智产云更多需求 -->
        <NeedNei ></NeedNei>
    </div>
</template>

<script>
    import ConTab from "../../components/common/ConTab";
    import NeedNei from "./base/NeedNei";
    // import Qs from 'qs'
    export default {
        name: "need",
        data(){
            return {
                
            }
        },
        created(){
            
        },
        methods:{
             TwoClick(item){
                //console.log(item);
                this.ConList = item
            },
        },
        components:{
            ConTab,
            NeedNei
        }
    }
</script>

<style scoped>

</style>
