 <template>
  <div class="need bc">
    <div class="w">
      <!-- 搜索 -->
      <div class="tip_top">
        <div class="inputs_filter">
          <input type="text" placeholder="请输关键字" v-model="search" />
          <div @click="submit()">搜索</div>
        </div>
      </div>
      <!-- 筛选 -->
      <div class="resource-list-search">
        <div class="resource-search-content">
          <div class="select-content">
            <span class="select-field-title">按领域:</span>
            <div class="select-field-list">
              <ul>
                <li
                  v-for="(item, key) in lingyu"
                  :class="key == index3 ? 'cur' : ''"
                  @click="handle3(item, key)"
                  :key="item.id"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
          <!-- <div class="select-content">
            <span class="select-field-title">按需求类别:</span>
            <div class="select-field-list">
              <ul>
                <li
                  v-for="(item, key) in type"
                  :class="key == index2 ? 'cur' : ''"
                  @click="handle2(item, key)"
                  :key="item.id"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div> -->
          <div class="select-content">
            <span class="select-field-title">按紧急程度：</span>
            <div class="select-field-list">
              <ul>
                <li
                  v-for="(item, idx) in urgency"
                  :class="idx == index1 ? 'cur' : ''"
                  @click="handle1(item, idx)"
                  :key="item.id"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </div>
          <div class="select-content">
            <span class="select-field-title">按所在地区：</span>
            <div class="select-field-list">
              <ul>
                <li
                  v-for="(item, key) in area"
                  :class="key == index ? 'cur' : ''"
                  @click="handle(item, key)"
                  :key="item.id"
                >
                  {{ item.title }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="checks_wraps">
        <div class="allLists">
          <ul class="policyWraps">
            <li
              class="policyItem"
              v-for="(item, idx) in items"
              :key="idx"
              @click="needs(item.id)"
            >
              <div class="checkLinks">
                <div class="titles">{{ item.title }}</div>
                <ul class="rightChecks">
                  <li class="checkAddress">
                    <div class="checkInner">
                      <i class="el-icon-location"></i>
                      {{ item.address1 }}
                    </div>
                  </li>
                  <li class="checkTechm">
                    <i class="el-icon-menu"></i>{{ item.territory1 }}
                  </li>
                  <li class="zonemb">
                    <i class="el-icon-time"></i>{{ item.updated_at }}
                  </li>

                  <li class="activeprice">面议</li>
                </ul>
                <div class="titleTech">
                  <span>需求介绍：</span><span>{{ item.content }} </span>
                </div>
              </div>
            </li>
          </ul>
          <!-- 分页 -->
          <div class="pagination" style="margin-top: 49px">
            <pagination
              class="pages"
              v-show="total > 0"
              :total="total"
              layout="prev, pager, next"
              :page.sync="listQuery.page"
              :limit.sync="listQuery.limit"
              @pagination="getNeed"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { NeedList, demand, Type, Urgency, Ares } from "../../../common/js/api";
import Pagination from "@/common/Pagination";

export default {
  name: "NeedNei",
  components: { Pagination },

  data() {
    return {
      policy: [],
      search: "",
      total: 0, //总条目数
      listQuery: {
        limit: 6,
        page: 1,
      },
      index: 0,
      index1: 0,
      index2: 0,
      index3: 0,
      lingyu: [], //领域
      type: [], //需求类别
      urgency: [], //紧急程度
      area: [], //地区
      list: [],
    };
  },
  created() {
    this.getNeed();
    this.getDemand();
    // this.getType();
    this.getUrgency();
    this.getAres();
  },
  computed: {
    //过滤方法
    items: function () {
      var _search = this.search;
      if (_search) {
        //es6 filter过滤匹配，有则返回当前，无则返回所有
        return this.policy.filter(function (e) {
          //匹配所有字段
          return Object.keys(e).some(function (key) {
            return e[key];
          });
        });
      }
      return this.policy;
    },
  },

  methods: {
    //智产云 更多需求列表
    getNeed() {
      NeedList({
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      })
        .then((res) => {
          console.log(res);
          this.data(res);
        })
        .catch(() => {});
    },
    // 搜索
    submit() {
      NeedList({
        keywords: this.search,
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      })
        .then((res) => {
          console.log(res);
          this.data(res);
        })
        .catch(() => {});
    },
    data(res) {
      this.policy = res.data.data;
      this.total = res.data.total;
      for (let i = 0; i < this.policy.length; i++) {
        this.policy[i].address1 = this.policy[i].address.title;
        this.policy[i].territory1 = this.policy[i].territory.name;
      }
    },
    handle3: function (item, idx) {
      this.index3 = idx;
      this.$set(this.list, 0, item.id);
      this.a();
    },
    // handle2: function (item, idx) {
    //   this.index2 = idx;

    //   this.$set(this.list, 1, item.id);
    //   this.a();
    // },
    handle1: function (item, idx) {
      this.index1 = idx;
      this.$set(this.list, 2, item.id);
      this.a();
    },
    //  地区
    handle: function (item, key) {
      this.index = key;
      this.$set(this.list, 3, item.id);
      this.a();
    },
    a() {
      var data = {
        keywords: this.search,
        address: this.list[3],
        urgency: this.list[2],
        // type: this.list[1],
        territory: this.list[0],
        page: this.listQuery.page,
        limits: this.listQuery.limit,
      };

      NeedList(data)
        .then((res) => {
          this.data(res);
        })
        .catch(() => {});
    },

    // 详情
    needs(id) {
      this.$router.push({
        path: "/need_details",
        query: {
          id: id,
        },
      });
    },

    getDemand() {
      demand({})
        .then((res) => {
          var b = { name: "全部" };
          res.data.unshift(b);
          this.lingyu = res.data;
        })
        .catch(() => {});
    },
    // getType() {
    //   Type({})
    //     .then((res) => {
    //       var b = { name: "全部" };
    //       res.data.unshift(b);
    //       this.type = res.data;
    //     })
    //     .catch(() => {});
    // },
    getUrgency() {
      Urgency({})
        .then((res) => {
          var b = { name: "全部" };
          res.data.unshift(b);
          this.urgency = res.data;
        })
        .catch(() => {});
    },
    getAres() {
      Ares({})
        .then((res) => {
          var b = { title: "全部" };
          res.data.unshift(b);
          this.area = res.data;
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="less" scoped>
.tip_top {
  width: 100%;
  //   height: 34px;
  background-color: #3892eb;
  padding: 5px 0;
  color: #fff;
  padding: 9px 9px 10px 17px;
  line-height: 36px;
  .inputs_filter {
    width: 100%;
    height: 34px;
    line-height: 34px;
    border-radius: 4px;
    cursor: pointer;
    input {
      display: inline-block;
      background-color: #fff;
      border-radius: 20px;
      height: 34px;
      width: 1074px;
      padding: 4px 20px;
      line-height: 26px;
      box-sizing: border-box;
    }
    div {
      display: inline-block;
      width: 90px;
      height: 34px;
      text-align: center;
    }
  }
}
.resource-list-search {
  width: 1200px;
  box-sizing: border-box;
  padding: 10px 40px 25px 20px;
  background: #ffffff;
  margin: 0 auto;
  margin-top: 20px;
  margin-bottom: 20px;
  border-radius: 4px;
  .select-content {
     display: flex;
    align-items: flex-start;
    margin-top: 20px;
    font-size: 14px;

     .select-field-title {
      width: 100px;
      display: inline-block;
 
    }
    .select-field-list {
      width: 1000px;
      display: inline-block;
      li {
        padding: 0 15px;
        display: inline-block;
        color: #999;
        cursor: pointer;
          
         height: 27px;
        line-height: 27px;
        margin-bottom: 5px;
      }
      li.cur {
        background: #2a71d2;
        border-radius: 2px;
        color: #fff;
      }
    }
  }
}
.checks_wraps {
  padding: 10px 25px 25px 20px;
  background: #ffffff;
  cursor: pointer;
  .policyItem {
    border-bottom: 1px solid rgba(237, 237, 237, 100);

    .titles {
      margin-top: 17px;
      margin-bottom: 11px;
      color: rgba(16, 16, 16, 100);
      font-size: 18px;
      text-align: left;
      font-weight: 700;
    }
    .rightChecks > li {
      display: inline-block;
      margin-right: 28px;
      font-size: 14px;
      i {
        color: #3892eb;
        margin-right: 8px;
      }
    }
    .rightChecks {
      margin-bottom: 13px;
      .activeprice {
        float: right;
        color: rgba(255, 59, 59, 100);
        font-size: 18px;
      }
    }
    .titleTech {
      margin-bottom: 11px;
      line-height: 1.5;
    }
  }
}
</style>